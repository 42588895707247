/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const UPLOAD_COMPANY_BENEFICIARIES = gql`
  mutation uploadCompanyBeneficiaries($file: Upload!) {
    uploadCompanyBeneficiaries(file: $file) {
      beneficiaries {
          id
        }
      }
    }
`;
